import React from 'react';
import Contact from '../sections/Contact';
import {useData} from '../data/contact';

export default function Kontakt() {
  const data = useData();
  
  return (
    <Contact data={data}/>
  );
}
