import {useContactIcons} from './useContactIcons'

const useContactData = ()=>{;
    const icons = useContactIcons();
    const data = [
        {
            title: 'Chcesz coś wycenić',
            icon: icons.laptop,
            email:'marketing@artprogres.com.pl'
        },
        {
            title: 'Szukasz zatrudnienia',
            icon: icons.bag,
            email:'praca@artprogres.com.pl'
        },
        {
            title: 'Zapraszamy do współpracy',
            icon: icons.pen,
            email:'produkcja@artprogres.com.pl'
        },
        {
            title: 'Pytania do księgowości',
            icon: icons.comment,
            email:'ksiegowosc@artprogres.com.pl'
        }
    ];
    return data;
}
export default useContactData;
