import React from 'react';
import useContactData from '../shared/Hooks/ContactData/useContactData';
import Image from 'gatsby-image';
import {useBackgroundImage} from '../shared/Hooks/Background';

export const useData = () => {
  const cards = useContactData();
  const backgroundImgs = useBackgroundImage();

  return {
    seo: {
      title: 'Kontakt',
    },
    parallaxText: (
      <Image fixed={backgroundImgs.contact.childImageSharp.fixed}/>
    ),
    title: 'Kontakt',
    topLayout: {
      title: <>Zapraszamy do&nbsp;współpracy</>,
      text: <>
        Zainteresowała Cię nasza oferta? <br />
        Potrzebujesz nieszablonowego rozwiązania POSM? <br />
        Napisz lub zadzwoń - nasi specjaliści wspomogą Twoją wizję wiedzą
        i&nbsp;doświadczeniem.
      </>,
    },
    cardContainer: {
      title: <>Chcesz z nami porozmawiać?<br/></>,
      callText: 'Zadzwoń',
      cards,
    }
  }
}
